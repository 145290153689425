// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '../public/assets/css/plugins.css';
@import '../public/assets/scss/style';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'node_modules/react-modal-video/scss/modal-video.scss';

// Custom.
.header-area .header-wrapper .logo a img {
	width: 2rem;
}

.about-inner .section-title h2.title {
	margin-top: 4rem;
}

.service.service__style--1 .icon svg {
	stroke-width: 1 !important;
}

.service.service__style--1 .icon {
	font-size: 54px;
	font-weight: 400;
	margin-bottom: 23px;
	display: inline-flex;
	color: #6e14ea;
}

.footer-right .footer-link ul.ft-link li:hover a,
.mainmenunav ul.mainmenu > li:hover > a {
	color: $color-5;
}

.breadcrumb-inner ul.page-list li.breadcrumb-item.active {
	color: $color-5;
}
