/*==========================
    Service Area
===========================*/

.service {
	// Service Style One
	&.service__style--1 {
		padding: 40px 0;
		@extend %transition;
		@extend %posrelative;
		z-index: 2;
		@media #{$sm-layout} {
			padding: 15px 0;
		}
		@media #{$md-layout} {
			padding: 20px 0;
		}
		.icon {
			img {
				margin-bottom: 34px;
				@media #{$sm-layout} {
					margin-bottom: 14px;
					height: 53px;
				}
				@media #{$large-mobile} {
					margin-bottom: 13px;
					height: 45px;
				}
			}
		}
		.content {
			h4 {
				&.title {
					margin-bottom: 20px;
					font-weight: 400;
					font-size: 24px;
					@media #{$sm-layout} {
						margin-bottom: 12px;
						font-weight: 400;
						font-size: 20px;
					}
					@media #{$large-mobile} {
						margin-bottom: 9px;
						font-size: 18px;
					}
				}
			}
			p {
				opacity: 0.75;
				font-weight: 300;
			}
		}
	}
	&.service__style--2 {
		padding: 30px 35px;
		@extend %transition;
		@extend %posrelative;
		z-index: 2;
		border-radius: 10px;
		@media #{$laptop-device} {
			padding: 30px 28px;
		}
		@media #{$md-layout} {
			padding: 30px 25px;
		}
		@media #{$sm-layout} {
			padding: 30px 14px;
		}
		@media #{$large-mobile} {
			padding: 30px 18px;
			margin-top: 30px;
		}

		&::before {
			position: absolute;
			content: '';
			left: 0;
			top: 0;
			background-image: linear-gradient(to right, #c210f6, #6e14ea);
			width: 100%;
			height: 100%;
			z-index: -1;
			opacity: 0;
			visibility: hidden;
			@extend %transition;
			border-radius: 10px;
			@media #{$large-mobile} {
				opacity: 1;
				visibility: visible;
			}
		}
		.icon {
			font-size: 54px;
			font-weight: 400;
			margin-bottom: 23px;
			display: inline-flex;
			color: $theme-color;
			@extend %transition;
			@media #{$md-layout} {
				margin-bottom: 11px;
			}
			@media #{$large-mobile} {
				color: #ffffff;
			}
			svg {
				stroke-width: 1 !important;
			}
		}
		.content {
			h3 {
				&.title {
					margin-bottom: 19px;
					font-weight: 500;
					@extend %transition;
					@media #{$laptop-device} {
						font-size: 19px;
					}
					@media #{$sm-layout} {
						font-size: 20px;
					}
					@media #{$md-layout} {
						margin-bottom: 8px;
					}
					@media #{$large-mobile} {
						color: #ffffff;
					}
					a {
						color: inherit;
						@media #{$large-mobile} {
							color: #ffffff;
						}
					}
				}
			}
			p {
				@extend %transition;
				color: rgba(29, 29, 36, 0.75);
				@media #{$large-mobile} {
					color: #ffffff;
				}
			}
		}

		&:hover {
			box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
			transform: translateY(-5px);
			&::before {
				opacity: 1;
				visibility: visible;
			}
			.icon {
				color: #ffffff;
			}
			.content {
				h3 {
					&.title {
						@extend %textwhite;
					}
				}
				p {
					@extend %textwhite;
				}
			}
		}

		&.service-left-align {
			display: flex;
			padding: 50px 24px;
			.icon {
				padding-right: 20px;
			}
		}
	}
}

/* ---------------------------
    Standard Service
-------------------------------*/
.standard-service {
	.thumbnai {
		img {
			border-radius: 5px;
		}
	}
	.content {
		h3 {
			font-size: 19px;
			margin-top: 30px;
			margin-bottom: 10px;
			font-weight: 600;
			a {
				@extend %transition;
				&:hover {
					color: $theme-color;
				}
			}
		}
		p {
			font-size: 15px;
			line-height: 24px;
		}
		a {
		}
	}
}

.text-center,
.text-left {
	.service {
		&.service__style--2 {
			padding: 60px 45px;
			@media #{$sm-layout} {
				padding: 30px 14px;
			}
			@media #{$lg-layout} {
				padding: 30px 14px;
			}
			@media #{$md-layout} {
				padding: 30px 14px;
			}
		}
	}
}

// Sercvice Style One
.service-white {
	// Service Style One
	.service__style--1 {
		z-index: 2;
		.content {
			h4 {
				&.title {
					color: #c6c9d8;
				}
			}
			p {
				color: #c6c9d8;
			}
		}
	}
}

/*---------------------------
    Paralax Service Style
-----------------------------*/

.rn-paralax-service {
	.service__style--2 {
		z-index: 2;
		padding: 40px 40px;
		background: rgba(255, 255, 255, 0.3);
		margin-top: 40px;
		overflow: hidden;
		border: 2px solid transparent;

		@media #{$sm-layout} {
			padding: 30px 20px;
			background: transparent;
			margin-top: 30px;
		}

		.icon {
			color: #ffffff;
		}
		.content {
			h3 {
				&.title {
					font-size: 19px;
					color: #ffffff;
				}
			}
			p {
				color: rgba(255, 255, 255, 0.75);
			}
		}
		&:hover {
			border: 2px solid $theme-color;
			&::before {
				border-radius: 0;
			}
		}
	}
}

/* Creative Agency Service  */
.creative-service-wrapper {
	.row {
		&.creative-service {
			a {
				display: flex;
				height: 100%;
			}
			.service {
				&.service__style--2 {
					margin-top: 30px;
					background: #f6f6f6;
				}
			}
		}
	}
}

.service-one-wrapper {
	@media #{$md-layout} {
		margin: 0 -40px;
	}
}
